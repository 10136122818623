<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light text-center">
            <a
              @click="openExternalLink('https://play.google.com/store/apps/details?id=net.pirassununga.noticias', 'link_pira_noticias')"
              class="mx-n4 red--text"
            >Pira Notícias</a>

            <br class="md-br" />

            <span
              class="ml-1 text--secondary"
              style="font-size: 12px"
            >As principais notícias de Pirassununga e região</span>
          </div>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center">
            <strong>&lt;/&gt;</strong> por
            <a
              @click="openExternalLink('https://marquesini.com.br', 'link_author_site')"
              class="ml-n4 red--text"
            >Thiago Marquesini</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    methods: {
      openExternalLink (href, event) {
        this.$firebase.analytics().logEvent('link', event)
        window.open(href, '_blank')
      },
    },
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
  @media only screen and (min-width: 960px)
    .md-br
      display: none
</style>
